import {
  categories,
  Category,
  EquipmentData,
  EventType,
  eventTypes,
  Priority,
  TaskRepetitionUnit,
  taskRepetitionUnits,
  DateRange,
  MaintenanceNotification,
  StatusWithNoneAndCancellationAndApproverAndWorkOrderStates
} from '@hconnect/common/types'
import {
  equipmentToParameter,
  parameterToEquipment,
  QuickSelectSlots,
  generateTimeRangeFromUrlParam,
  getTimeRangeOrQuickSelectSlotsString
} from '@hconnect/common/utils'
import {isUndefined, omitBy, pick} from 'lodash'

import {
  PmNotificationStatus,
  pmNotificationStatusListApprovalStates,
  SortBy,
  SortOrder,
  statusListWithApproverStates,
  WorkOrderStatus,
  workOrderStatusListApprovalStates,
  WorkOrderSortBy
} from '../../types/shiftHandover.types'

export type PmNotificationFilterOptions = {
  equipment?: EquipmentData
  pmNotificationStatus?: PmNotificationStatus[]
  freeText?: string
  sortNotificationsBy?: WorkOrderSortBy
  notifSortDir?: SortOrder
  timeRange?: DateRange | QuickSelectSlots
}
export type WorkOrdersFilterOptions = {
  equipment?: EquipmentData
  workOrderStatus?: WorkOrderStatus[]
  freeText?: string
  sortWorkOrderBy?: WorkOrderSortBy
  woSortDir?: SortOrder
  timeRange?: DateRange | QuickSelectSlots
  orderType?: string[]
}

export type FilterOptions = {
  category?: Category[]
  timeRange?: DateRange | QuickSelectSlots
  eventType?: EventType[]
  processStage?: string[]
  status?: StatusWithNoneAndCancellationAndApproverAndWorkOrderStates[]
  priority?: Priority[]
  parameterChangeFunctions?: string[]
  equipment?: EquipmentData
  mainEquipment?: EquipmentData
  repetition?: TaskRepetitionUnit[]
  stoppageCode?: string[]
  freeText?: string
  sortBy?: SortBy
  sortOrder?: SortOrder
  maintenanceNotificationExists?: MaintenanceNotification[]
}

export type CombinedWorkOrdersFilterOptions = PmNotificationFilterOptions & WorkOrdersFilterOptions
export type AllFilterOptions = FilterOptions & CombinedWorkOrdersFilterOptions

export type DesktopFilterOptionsKeys = keyof Omit<FilterOptions, 'timeRange'>
export type AllDesktopFilterOptionsKeys = keyof Omit<AllFilterOptions, 'timeRange'>

export type RecurrenceTasksFilterOptionsKeys = keyof Pick<
  FilterOptions,
  | 'repetition'
  | 'equipment'
  | 'mainEquipment'
  | 'processStage'
  | 'category'
  | 'sortBy'
  | 'sortOrder'
>
export type PmNotificationFilterOptionsKeys = keyof Pick<
  PmNotificationFilterOptions,
  'equipment' | 'freeText' | 'pmNotificationStatus'
>
export type WorkOrdersFilterOptionsKeys = keyof Pick<
  WorkOrdersFilterOptions,
  'equipment' | 'freeText' | 'workOrderStatus' | 'orderType'
>
export type DesktopTasksFilterOptionsKeys = keyof Pick<
  FilterOptions,
  'priority' | 'equipment' | 'mainEquipment' | 'processStage' | 'category'
>

export type DesktopShiftSummaryFilterOptionsKeys = keyof Omit<
  FilterOptions,
  'repetition' | 'timeRange'
>

export const defaultDesktop = ['timeRange']

export const desktopFilterOptions: DesktopFilterOptionsKeys[] = [
  'processStage',
  'equipment',
  'mainEquipment',
  'eventType',
  'category',
  'priority',
  'parameterChangeFunctions',
  'status',
  'stoppageCode',
  'freeText',
  'maintenanceNotificationExists'
]

export const pmNotificationFilterOptions: PmNotificationFilterOptionsKeys[] = [
  'equipment',
  'freeText',
  'pmNotificationStatus'
]
export const workOrderFilterOptions: WorkOrdersFilterOptionsKeys[] = [
  'equipment',
  'freeText',
  'workOrderStatus',
  'orderType'
]

export const recurrenceTasksFilterOptions: RecurrenceTasksFilterOptionsKeys[] = [
  'repetition',
  'equipment',
  'mainEquipment',
  'processStage',
  'category'
]

export const desktopTasksFilterOptions: DesktopTasksFilterOptionsKeys[] = [
  'processStage',
  'equipment',
  'mainEquipment',
  'category',
  'priority'
]

export const desktopShiftSummaryFilterOptions: DesktopShiftSummaryFilterOptionsKeys[] = [
  'processStage',
  'equipment',
  'mainEquipment',
  'eventType',
  'category',
  'priority',
  'status',
  'stoppageCode'
]

export const sortingFilterParams: DesktopFilterOptionsKeys[] = ['sortBy', 'sortOrder']
export const sortingWorkOrdersFilterParams: AllDesktopFilterOptionsKeys[] = [
  'woSortDir',
  'sortWorkOrderBy'
]
export const sortingNotificationsFilterParams: AllDesktopFilterOptionsKeys[] = [
  'notifSortDir',
  'sortNotificationsBy'
]

export const filterSettingsToUrl = (settings: FilterOptions): string => {
  return filterSettingsToUrlParams(settings).toString()
}

export const filterSettingsToUrlParams = (settings: FilterOptions): URLSearchParams => {
  const params = new URLSearchParams()

  // time ranges handled explicitly
  if (settings.timeRange) {
    const timeRangeString = getTimeRangeOrQuickSelectSlotsString(settings.timeRange)
    params.append('timeRange', timeRangeString)
  }
  if (settings.mainEquipment) {
    params.append('mainEquipment', equipmentToParameter(settings.mainEquipment))
  }
  if (settings.equipment) {
    params.append('equipment', equipmentToParameter(settings.equipment))
  }

  // all other properties are strings and can simply be copied over
  const ignoredKeys: (keyof FilterOptions)[] = ['timeRange', 'equipment', 'mainEquipment']
  let key: keyof FilterOptions
  for (key in settings) {
    // time ranges handled explicitly and should be skipped
    if (ignoredKeys.includes(key)) continue
    const value = settings[key]
    // TODO: @cockpit add type guard for variable 'value' below
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    if (value && !Array.isArray(value)) params.append(key, value.toString())
    if (value && Array.isArray(value) && value.length > 0) {
      if (value.length === 1) params.append(key, value[0].toString())
      else params.append(key, value.map((v) => v.toString()).join(','))
    }
  }

  return params
}

export function getParam(params: URLSearchParams, key: string) {
  return params.get(key) ?? undefined
}

const isNumeric = (num: unknown) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num as number)

function getArrayParam<T extends string | number = string>(
  params: URLSearchParams,
  key: string,
  parse: 'number' | 'string' = 'string'
) {
  const p = params.get(key) ?? undefined
  if (!p) return undefined
  const splitted = p.split(',')
  if (splitted.length > 0) {
    if (parse === 'number' && splitted.every((split) => isNumeric(split))) {
      return splitted.map((str) => parseInt(str, 10)) as T[]
    }
    return splitted as T[]
  } else return undefined
}

// eslint-disable-next-line complexity
export const urlToFilterSettings = (
  params: URLSearchParams,
  timezone: string,
  warn: (text: string) => void = console.warn
): FilterOptions => {
  const result: AllFilterOptions = {}

  const utcTimeRange = getParam(params, 'timeRange')
  if (utcTimeRange) {
    result.timeRange = generateTimeRangeFromUrlParam(utcTimeRange, timezone)
  }

  const processStage = getArrayParam(params, 'processStage')
  if (processStage) result.processStage = processStage

  const equipment = parameterToEquipment(getParam(params, 'equipment'))
  if (equipment) result.equipment = equipment

  const mainEquipment = parameterToEquipment(getParam(params, 'mainEquipment'))
  if (mainEquipment) result.mainEquipment = mainEquipment

  const orderType = getArrayParam<string>(params, 'orderType')
  if (orderType) result.orderType = orderType

  const eventType = getArrayParam<EventType>(params, 'eventType')
  if (eventType) {
    const valid = validateArrayValues<EventType>(eventType, [...eventTypes], warn, 'eventType')
    if (valid) result.eventType = eventType
  }

  const priority = getArrayParam<Priority>(params, 'priority', 'number')
  if (priority) {
    const valid = priority.every((val) => isNumeric(val))
    if (valid) result.priority = priority
    else warn(`${priority} is not a valid value for priority`)
  }

  const status = getArrayParam<StatusWithNoneAndCancellationAndApproverAndWorkOrderStates>(
    params,
    'status'
  )
  if (status) {
    const valid = validateArrayValues<StatusWithNoneAndCancellationAndApproverAndWorkOrderStates>(
      status,
      [...statusListWithApproverStates],
      warn,
      'status'
    )
    if (valid) result.status = status
  }

  const pmNotificationStatus = getArrayParam<PmNotificationStatus>(params, 'pmNotificationStatus')
  if (pmNotificationStatus) {
    const valid = validateArrayValues<PmNotificationStatus>(
      pmNotificationStatus,
      pmNotificationStatusListApprovalStates,
      warn,
      'pmNotificationStatus'
    )
    if (valid) result.pmNotificationStatus = pmNotificationStatus
  }

  const workOrderStatus = getArrayParam<WorkOrderStatus>(params, 'workOrderStatus')
  if (workOrderStatus) {
    const valid = validateArrayValues<WorkOrderStatus>(
      workOrderStatus,
      workOrderStatusListApprovalStates,
      warn,
      'workOrderStatus'
    )
    if (valid) result.workOrderStatus = workOrderStatus
  }

  const category = getArrayParam<Category>(params, 'category')
  if (category) {
    const valid = validateArrayValues<Category>(category, [...categories], warn, 'category')
    if (valid) result.category = category
  }

  const recurrence = getArrayParam<TaskRepetitionUnit>(params, 'repetition')
  if (recurrence) {
    const valid = validateArrayValues<TaskRepetitionUnit>(
      recurrence,
      [...taskRepetitionUnits],
      warn,
      'repetition'
    )
    if (valid) result.repetition = recurrence
  }

  result.stoppageCode = getArrayParam<string>(params, 'stoppageCode')

  result.freeText = getParam(params, 'freeText')

  result.sortBy = getParam(params, 'sortBy') as SortBy
  result.sortWorkOrderBy = getParam(params, 'sortWorkOrderBy') as WorkOrderSortBy
  result.sortNotificationsBy = getParam(params, 'sortNotificationsBy') as WorkOrderSortBy

  result.sortOrder = getParam(params, 'sortOrder') as SortOrder
  result.woSortDir = getParam(params, 'woSortDir') as SortOrder
  result.notifSortDir = getParam(params, 'notifSortDir') as SortOrder
  result.maintenanceNotificationExists = getArrayParam<string>(
    params,
    'maintenanceNotificationExists'
  ) as MaintenanceNotification[]

  return result
}

function validateArrayValues<T extends string | number>(
  toValidate: T[],
  availableValues: T[],
  warn: (text: string) => void,
  key: string
): boolean {
  let valid = true
  toValidate.map((val) => {
    if (!availableValues.includes(val)) {
      valid = false
      warn(`${val} is not a valid value for ${key}`)
    }
  })
  return valid
}

export enum Page {
  Events = 'events',
  WorkOrders = 'workOrders',
  Notifications = 'notifications',
  Tasks = 'tasks',
  Summary = 'summary',
  Performance = 'performance',
  RecurringTasks = 'recurringTasks'
}
export const filterPageKeys: Record<Page, AllDesktopFilterOptionsKeys[]> = {
  [Page.Events]: [...desktopFilterOptions, ...sortingFilterParams],
  [Page.WorkOrders]: [...workOrderFilterOptions, ...sortingWorkOrdersFilterParams],
  [Page.Notifications]: [...pmNotificationFilterOptions, ...sortingNotificationsFilterParams],
  [Page.Tasks]: desktopTasksFilterOptions,
  [Page.Summary]: desktopShiftSummaryFilterOptions,
  [Page.RecurringTasks]: recurrenceTasksFilterOptions,
  [Page.Performance]: []
}

export const getPageScopedFilters = (
  filters: string,
  page: Page,
  timezone: string,
  defaultFilters?: Partial<FilterOptions>
): FilterOptions => {
  const filterOptions: FilterOptions = urlToFilterSettings(new URLSearchParams(filters), timezone)
  return {
    ...defaultFilters,
    ...omitBy(pick(filterOptions, ['timeRange', ...filterPageKeys[page]]), isUndefined)
  }
}
