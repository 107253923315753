import {StoppageFormFields} from '@hconnect/common/components/stoppageFormFields'
import {MAX_DESCRIPTION_CHARACTER_LIMIT} from '@hconnect/common/consts'
import {DateRange, Stoppage} from '@hconnect/common/types'
import {removeMarkdownTags, getQuickSelectFilter} from '@hconnect/common/utils'
import {
  shouldNotBeEmpty,
  shouldNotBeEmptyString,
  shouldNotBeEmptyStringWithMaxLength,
  ValidatorConfig
} from '@hconnect/common/validators'
import React, {useState} from 'react'

import {useRunningTimes} from '../../hooks/api/useRunningTimes'
import {useConfig} from '../../hooks/useConfig'
import {ShiftEventAndUpdateEvent} from '../../types/shiftHandover.types'
import {toDateRange} from '../../utils/timeRangeHelper'
import {
  requiredEquipmentNumberValidator,
  stoppageCodeValidator,
  stoppageReasonValidator,
  validToValidator
} from '../../validators/shiftEvent.validators'
import {EventInfo} from '../eventProperties/EventInfo'

type TypeOfStoppage = Stoppage
type PossibleErrors = keyof TypeOfStoppage | 'stoppageCode' | 'stoppageReason' | 'equipmentNumber'

export type Props = {
  item: Partial<TypeOfStoppage>
  updateMultiPart: (delta) => void
  validationError: Map<PossibleErrors, string>
  children: React.ReactNode
  hideStoppageDateType?: boolean
  durationEditOptions: {
    disableStart: boolean
    disableEnd: boolean
  }
}

// As the UI has to reflect the visible fields and knows best which of them is Required,
// the Validator config is put here to have both aligned at all time.

export const stoppageValidatorConfig: ValidatorConfig<ShiftEventAndUpdateEvent> = new Map([
  ['title', shouldNotBeEmptyString<ShiftEventAndUpdateEvent>()],
  ['equipmentNumber', requiredEquipmentNumberValidator],
  ['eventType', shouldNotBeEmpty<ShiftEventAndUpdateEvent>()],
  ['stoppageCode', stoppageCodeValidator],
  ['stoppageReason', stoppageReasonValidator],
  ['stoppageStart', shouldNotBeEmpty<ShiftEventAndUpdateEvent>()],
  ['stoppageEnd', validToValidator],
  [
    'description',
    shouldNotBeEmptyStringWithMaxLength<ShiftEventAndUpdateEvent>(
      MAX_DESCRIPTION_CHARACTER_LIMIT,
      removeMarkdownTags
    )
  ]
])

/**
 * if you consider using this component, check first src/container/EventFormContainer.tsx,
 * as it handles the validation and the stuff around this form,
 * it might already solve all your needs
 */

export const StoppageForm: React.FC<Props> = ({
  children,
  item,
  updateMultiPart,
  validationError,
  hideStoppageDateType,
  durationEditOptions
}) => {
  const {
    timezone,
    plantId,
    shifts,
    plantNow,
    kilnStoppageCodes,
    rawMillStoppageCodes,
    finishMillStoppageCodes,
    alternativeFuelInstallationStoppageCodes
  } = useConfig()
  const dateRange = getQuickSelectFilter('currentShift', shifts, plantNow())
  const [range, setRange] = useState(dateRange)
  const timeRange: DateRange = toDateRange(range)

  const {data = [], isFetching} = useRunningTimes(timeRange, {
    enabled: !hideStoppageDateType,
    keepPreviousData: true
  })

  const loadMoreRunningTimes = () => {
    setRange([range[0].subtract(1, 'day'), range[1]])
  }

  const runningTimesData = !hideStoppageDateType
    ? {
        data,
        isLoading: isFetching,
        fetchMore: loadMoreRunningTimes
      }
    : undefined

  return (
    <>
      {'created' in item && <EventInfo item={item as Partial<Stoppage>} />}

      <StoppageFormFields
        item={item}
        updateMultiPart={updateMultiPart}
        validationError={validationError}
        timezone={timezone}
        plantId={plantId}
        withPriority={true}
        withCategory={true}
        runningTimesData={runningTimesData}
        stoppageCodes={{
          kilnStoppageCodes: kilnStoppageCodes,
          rawMillStoppageCodes: rawMillStoppageCodes,
          finishMillStoppageCodes: finishMillStoppageCodes,
          alternativeFuelInstallationStoppageCodes: alternativeFuelInstallationStoppageCodes
        }}
        durationEditOptions={durationEditOptions}
      >
        {children}
      </StoppageFormFields>
    </>
  )
}
