import {ContentBox} from '@hconnect/common/components/ContentBox'
import {EventTypeTag} from '@hconnect/common/components/shiftEventFormFields'
import {MAX_DESCRIPTION_CHARACTER_LIMIT} from '@hconnect/common/consts'
import {EventType} from '@hconnect/common/types'
import {removeMarkdownTags} from '@hconnect/common/utils'
import {
  shouldNotBeEmpty,
  shouldNotBeEmptyString,
  shouldNotBeEmptyStringWithMaxLength,
  validateAll,
  ValidatorConfig
} from '@hconnect/common/validators'
import {Check, Close} from '@mui/icons-material'
import {Box, Button, Paper} from '@mui/material'
import {TFunction} from 'i18next'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  isStoppageEventType,
  isPartialStoppage,
  isTask,
  isParameterChange
} from '../../common/utils/eventType'
import {
  ParameterChangeForm,
  parameterChangeValidatorConfig
} from '../../components/eventForm/ParameterChangeForm'
import {SharedEventForm} from '../../components/eventForm/SharedEventForm'
import {StoppageForm, stoppageValidatorConfig} from '../../components/eventForm/StoppageForm'
import {TaskForm, taskValidatorConfig} from '../../components/eventForm/TaskForm'
import {useTrackAnalyticsEvent} from '../../hooks/useTrackAnalyticsEvents'
import {ChecklistsInputSettings, SharedEventStructure} from '../../types/shareEventForm.types'
import {
  BaseEvent,
  EventUpdate,
  EventEditModes,
  EventEditModeEnum,
  Task,
  ParameterChange,
  ShiftEventAndUpdateEvent
} from '../../types/shiftHandover.types'
import {RepetitionInputsSettings} from '../../types/taskRepetitionInfo.types'
import {commonFormScheduleEndValidator} from '../../validators/shiftEvent.validators'

export type Props = {
  item: Partial<BaseEvent>
  doClose(): void
  doSubmit(item: EventUpdate): void
  isCreateMode?: boolean
  editMode?: EventEditModes
  eventTypeLabelPrefix?: string
  children: React.ReactNode
  hideStoppageDateType?: boolean
  checklistsInputSettings?: ChecklistsInputSettings
}

type State = {
  originalItem: Partial<BaseEvent>
  item: Partial<BaseEvent>
  showValidation: boolean
  validationError: Map<keyof BaseEvent, string>
}

const eventValidatorConfig: ValidatorConfig<ShiftEventAndUpdateEvent> = new Map([
  ['category', shouldNotBeEmpty<ShiftEventAndUpdateEvent>()],
  [
    'description',
    shouldNotBeEmptyStringWithMaxLength<ShiftEventAndUpdateEvent>(
      MAX_DESCRIPTION_CHARACTER_LIMIT,
      removeMarkdownTags
    )
  ],
  ['status', shouldNotBeEmpty<ShiftEventAndUpdateEvent>()],
  ['title', shouldNotBeEmptyString<ShiftEventAndUpdateEvent>()],
  ['schedule', commonFormScheduleEndValidator]
])

export const getTypeSpecificValidatorConfig = (type?: EventType) => {
  if (!type) return eventValidatorConfig
  if (type === 'task') return taskValidatorConfig
  if (isStoppageEventType(type)) return stoppageValidatorConfig
  if (type === 'parameterChange') return parameterChangeValidatorConfig
  return eventValidatorConfig
}

const eventFormStateReducer = <K extends keyof BaseEvent>(
  state: State,
  validatorConfig: ValidatorConfig<ShiftEventAndUpdateEvent>,
  key: K,
  value: BaseEvent[K]
): State => {
  // do the change optimistically
  const next = {
    ...state,
    item: {
      ...state.item,
      [key]: value
    }
  }

  // validate the input if needed
  if (state.showValidation && validatorConfig.has(key)) {
    const failed = validatorConfig.get(key)?.(value, state.item)
    if (failed) {
      if (failed !== next.validationError.get(key)) {
        next.validationError = new Map(next.validationError)
        next.validationError.set(key, failed)
      }
    } else {
      if (next.validationError.has(key)) {
        next.validationError = new Map(next.validationError)
        next.validationError.delete(key)
      }
    }
  }

  return next
}

// helper to maintain a fix pointer for an empty validationError map
const emptyMap = new Map()

const generateRepetitionInputsSettings = (
  isSeriesEvent: boolean,
  item: Partial<BaseEvent>,
  t: TFunction,
  editMode?: EventEditModes
): RepetitionInputsSettings => ({
  disableCheckbox: isSeriesEvent || (isTask(item) && !!(item.doNotStartBefore && item.dueDate)),
  disableSelect: editMode === EventEditModeEnum.SINGLE_IN_SERIES,
  checkboxMessage:
    isTask(item) && !!(item.doNotStartBefore && item.dueDate)
      ? t('shiftEvent.label.recurringDisabled')
      : undefined
})

const FormWrapper: React.FC<{
  eventType?: EventType
  eventTypeLabelPrefix?: string
  children: React.ReactNode
}> = ({children, eventType, eventTypeLabelPrefix}) => {
  if (isStoppageEventType(eventType)) {
    return (
      <ContentBox
        data-test-id="event-form"
        bodyWithPadding
        title={
          eventType && (
            <EventTypeTag
              eventType={eventType}
              eventTypeLabelPrefix={eventTypeLabelPrefix}
              data-test-id="event-form-container-event-type-label"
            />
          )
        }
        mode="max100PercentOfParentHeight"
      >
        {children}
      </ContentBox>
    )
  }
  return (
    <Paper
      data-test-id="event-form"
      sx={{
        maxHeight: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        p: 3,
        flexShrink: 1
      }}
    >
      {children}
    </Paper>
  )
}

export const getIsStoppageDateEditable = (
  isCreateMode: boolean | undefined,
  item: Partial<BaseEvent>
) => {
  if (!isPartialStoppage(item)) return false
  if (isCreateMode) return !!(item.stoppageStart || item.stoppageEnd)
  return !!item.pxtrendStoppageId
}

/**
 * Container component for the Event form, including validation and save button
 *
 * TODO the props.item will tell us what type of event to expect
 * so in theories we could derive the type of update functions and the type of the state from the type of the item
 * and could get rid to the types casts in this component
 */
export const EventFormContainer: React.FC<Props> = ({
  item: originalItem,
  doClose,
  doSubmit: parentDoSubmit,
  editMode,
  eventTypeLabelPrefix,
  hideStoppageDateType,
  isCreateMode,
  checklistsInputSettings = {disableSelect: false, showChecklists: false},
  children
}) => {
  const trackAnalyticsEvent = useTrackAnalyticsEvent()

  // state setup
  const [state, setState] = useState<State>({
    originalItem,
    item: originalItem,
    showValidation: false,
    validationError: emptyMap
  })

  // variables
  const item = state.item
  const validatorConfig = getTypeSpecificValidatorConfig(item.eventType)
  const disableSubmit = state.showValidation && state.validationError.size > 0

  // hooks
  const {t} = useTranslation()

  const isSeriesEvent =
    editMode === EventEditModeEnum.SINGLE_IN_SERIES || editMode === EventEditModeEnum.SERIES

  const repetitionInputsSettings: RepetitionInputsSettings = useMemo(
    () => generateRepetitionInputsSettings(isSeriesEvent, item, t, editMode),
    [editMode, isSeriesEvent, item, t]
  )

  const allowCancellation = !!editMode
  const disableStartEndChange = getIsStoppageDateEditable(isCreateMode, originalItem)

  // callbacks
  const doSubmit = () => {
    const validationError = validateAll<ShiftEventAndUpdateEvent>(validatorConfig, item)

    if (validationError.size === 0) {
      const stoppageCode = 'stoppageCode' in item && item.stoppageCode
      const category = 'category' in item && item.category
      trackAnalyticsEvent('onCategoryStoppageCodeEvent', {
        eventType: item.eventType as EventType,
        ...(stoppageCode && {stoppageCode}),
        ...(category && {category})
      })
      parentDoSubmit(item as EventUpdate)
    } else {
      setState((currentState: State) => ({
        ...currentState,
        showValidation: true,
        validationError
      }))
    }
  }

  const update = useCallback(
    (key, value) => {
      setState((currentState: State) => {
        if (currentState.item[key] === value) {
          // ignore updates that don't change anything, to prevent endless loops
          return currentState
        }
        return eventFormStateReducer(currentState, validatorConfig, key, value)
      })
    },
    [setState, validatorConfig]
  )

  const updateMultiPart = useCallback(
    (delta: Partial<BaseEvent>) => {
      setState((currentState: State) => {
        const nextItem = {
          ...currentState.item,
          ...delta
        } as BaseEvent

        const validationError = currentState.showValidation
          ? validateAll<ShiftEventAndUpdateEvent>(validatorConfig, nextItem) // TODO instead of validate all, just validate the "keyof delta"
          : currentState.validationError

        return {
          ...currentState,
          item: nextItem,
          validationError
        }
      })
    },
    [setState, validatorConfig]
  )

  return (
    <FormWrapper eventType={item.eventType} eventTypeLabelPrefix={eventTypeLabelPrefix}>
      {isPartialStoppage(item) ? (
        <StoppageForm
          item={item}
          hideStoppageDateType={hideStoppageDateType || disableStartEndChange}
          updateMultiPart={updateMultiPart}
          validationError={state.showValidation ? state.validationError : emptyMap}
          durationEditOptions={{
            disableStart: disableStartEndChange,
            disableEnd: disableStartEndChange
          }}
        >
          {children}
        </StoppageForm>
      ) : isTask(item) ? (
        <TaskForm
          allowCancellation={allowCancellation}
          editWithTaskSchedules={editMode === EventEditModeEnum.SERIES}
          repetitionInputsSettings={repetitionInputsSettings}
          checklistsInputSettings={checklistsInputSettings}
          disableDoNotStartBefore={isSeriesEvent}
          item={item}
          update={update}
          updateMultiPart={updateMultiPart}
          validationError={state.showValidation ? state.validationError : emptyMap}
          originalAssignees={(state.originalItem as Task).assignees}
        >
          {children}
        </TaskForm>
      ) : isParameterChange(item) ? (
        <ParameterChangeForm
          isEditMode={!!editMode}
          item={item}
          update={update}
          updateMultiPart={updateMultiPart}
          validationError={state.showValidation ? state.validationError : emptyMap}
          originalApprovers={
            (state.originalItem as ParameterChange).parameterChange?.approvers || []
          }
        >
          {children}
        </ParameterChangeForm>
      ) : (
        <SharedEventForm
          item={item as SharedEventStructure}
          update={update}
          updateMultiPart={updateMultiPart}
          validationError={state.showValidation ? state.validationError : emptyMap}
        >
          {children}
        </SharedEventForm>
      )}
      <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
        <Button
          startIcon={<Close />}
          variant="text"
          onClick={doClose}
          data-test-id="event-form-cancel-button"
        >
          {t('action.cancel')}
        </Button>
        <Button
          startIcon={<Check />}
          disabled={disableSubmit}
          color="primary"
          variant="contained"
          onClick={doSubmit}
          data-test-id="event-save-button"
        >
          {t('action.save')}
        </Button>
      </Box>
    </FormWrapper>
  )
}
