import {BarcodeScannerPopUp} from '@hconnect/common/components/barScanner/BarcodeScannerPopUp'
import {
  MainEquipmentSearchHierarchy,
  EquipmentSearchHierarchy,
  FormIconButton
} from '@hconnect/common/components/shiftEventFormFields'
import {useEquipment} from '@hconnect/common/hooks/useEquipment'
import {useMobileBreakPoint} from '@hconnect/common/hooks/useWidth'
import {EquipmentData, HttpError, EquipmentPlain, EquipmentIdType} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit'
import QrCodeIcon from '@mui/icons-material/QrCode'
import {Box} from '@mui/material'
import {AxiosError} from 'axios'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useConfig} from '../../hooks/useConfig'
import {BaseEvent} from '../../types/shiftHandover.types'

const EquipmentContainer = ({children}: {children: React.ReactNode}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        '&:not(:last-child)': {
          paddingBottom: 1
        },
        '&:last-child': {
          paddingTop: 1
        },
        gap: 2
      }}
    >
      {children}
    </Box>
  )
}

export type Props = {
  label?: React.ReactNode
  errorText?: React.ReactNode
  updateMultiPart: (delta: Partial<BaseEvent>) => void
  required?: boolean
  value: BaseEvent
  hideMainEquipmentField?: boolean
  disabled?: boolean
  equipmentIdType?: EquipmentIdType
}

export const EquipmentSearchContainer = (props: Props) => {
  const [isScannerOpen, setIsScannerOpen] = useState(false)
  const isMobile = useMobileBreakPoint()
  const {i18n} = useTranslation()
  const {notify} = useNotification()
  const {plantId} = useConfig()

  const {
    updateMultiPart,
    errorText,
    value: item,
    required,
    disabled,
    equipmentIdType = 'sapNumber'
  } = props

  const [equipment, setEquipment] = useState<EquipmentPlain | undefined>(
    'equipment' in item && item.equipment
      ? {
          equipment: item.equipment,
          path: []
        }
      : undefined
  )
  const [mainEquipment, setMainEquipment] = useState<EquipmentData | undefined>(
    'mainEquipment' in item && item.mainEquipment
      ? {
          id: item.mainEquipment.id,
          idType: item.mainEquipment.idType,
          text: item.mainEquipment.text
        }
      : undefined
  )
  // instead of setting up our own EquipmentPlain we could get the Details from the backend
  // but the hierarchy is currently only reflected in the suggestions, not in the selected value
  // const {data: equipment} =useSpecificEquipmentNumberSearchQuery(item)

  const updateMainEquipment = useCallback(
    (value?: EquipmentData) => {
      setMainEquipment(value)
      updateMultiPart({
        mainEquipment: value
      })
    },
    [updateMultiPart]
  )

  const updateEquipment = useCallback(
    (value?: EquipmentPlain) => {
      setEquipment(value)
      const mainEquipmentData = value?.mainEquipment
      if (mainEquipmentData !== undefined) {
        updateMultiPart({
          equipment: value?.equipment,
          processStage: value?.processStage?.code,
          mainEquipment: mainEquipmentData
        })
        setMainEquipment(mainEquipmentData)
      } else {
        updateMultiPart({
          equipment: value?.equipment,
          processStage: value?.processStage?.code
        })
      }
    },
    [updateMultiPart]
  )

  const [searchTerm, setSearchTerm] = useState<string | undefined>()
  useEquipment(plantId, searchTerm || '', equipmentIdType, i18n.language, {
    enabled: !!searchTerm,
    onSuccess: (item) => {
      if (item) {
        updateEquipment(item)
        setSearchTerm(undefined)
      }
    },
    onError: (error: AxiosError<HttpError>) => {
      if (error.response?.data.detail) {
        notify('error', error.response.data.detail)
        setSearchTerm(undefined)
      }
    }
  })

  return (
    <>
      <EquipmentContainer>
        <Box flex={1}>
          <EquipmentSearchHierarchy
            data-test-id="equipment-search-dropdown"
            errorText={errorText}
            plantId={plantId}
            onChange={(value) => updateEquipment(value)}
            value={equipment}
            required={required}
            disabled={disabled}
            mainEquipmentId={mainEquipment?.id}
          />
        </Box>
        {isMobile && (
          <>
            <FormIconButton
              data-test-id="equipment-search-container-scan-button"
              onClick={() => setIsScannerOpen(!isScannerOpen)}
              disabled={disabled}
            >
              <QrCodeIcon />
            </FormIconButton>

            <BarcodeScannerPopUp
              open={isScannerOpen}
              onClose={() => setIsScannerOpen(false)}
              onScan={setSearchTerm}
            />
          </>
        )}
      </EquipmentContainer>
      {!props.hideMainEquipmentField && (
        <EquipmentContainer>
          <Box flex={1}>
            <MainEquipmentSearchHierarchy
              plantId={plantId}
              errorText={errorText}
              onChange={(value) => updateMainEquipment(value)}
              value={mainEquipment}
              required={false}
              disabled={disabled}
            />
          </Box>
        </EquipmentContainer>
      )}
    </>
  )
}
