import {getEquipmentLabel} from '@hconnect/common/components/shiftEventLabels'
import {useEquipmentNumberSearchQuery} from '@hconnect/common/hooks/useEquipmentNumberSearchQuery'
import {filterSxStyles} from '@hconnect/common/styles'
import {EquipmentData} from '@hconnect/common/types'
import {Autocomplete, TextField, Paper, MenuItem} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useConfig} from '../../hooks/useConfig'

export type Props = {
  label?: React.ReactNode
  onChange?: (term: EquipmentData | undefined) => void
  placeholder?: string
  value?: EquipmentData
  removeFilter: (keyName: string) => void
}

const paper = (props) => <Paper elevation={8} style={{minWidth: '350px'}} {...props} />

export const EquipmentSearchField: React.FC<Props> = ({
  onChange = () => undefined,
  value: selectedEquipment,
  removeFilter,
  ...props
}: Props) => {
  const {t} = useTranslation()
  const [open, setOpen] = React.useState(false)
  const {plantId} = useConfig()

  const [searchTerm, setSelectedSearchTerm] = React.useState<string | undefined>(undefined)
  const searchEquipmentNumberQuery = useEquipmentNumberSearchQuery(searchTerm, plantId)

  const suggestions = useMemo(() => {
    if (!searchEquipmentNumberQuery.data) {
      return []
    }

    return searchEquipmentNumberQuery.data.map((plain) => plain.equipment)
  }, [searchEquipmentNumberQuery.data])

  return (
    <Autocomplete
      value={selectedEquipment ?? null}
      onChange={(event, next, reason) => {
        if (reason === 'clear') {
          if (selectedEquipment || searchTerm) {
            // first rest the filter
            onChange(undefined)
            setSelectedSearchTerm(undefined)
          } else {
            // then remove it
            removeFilter('equipment')
          }
        } else if (next && typeof next !== 'string' && next.id !== selectedEquipment?.id) {
          // new selected equipment
          onChange(next)
        }
      }}
      onInputChange={(event, newValue, reason) => {
        if (reason === 'input' && newValue !== searchTerm) {
          setSelectedSearchTerm(newValue)
        }
      }}
      open={open && suggestions.length > 0}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      options={suggestions}
      sx={{
        marginRight: {xs: 2, md: 0}
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('shiftEvent.label.equipment')}
          placeholder={t('pleaseStartTyping')}
          {...props}
          fullWidth={false}
          sx={filterSxStyles.textInputField}
          variant="filled"
        />
      )}
      PaperComponent={paper}
      forcePopupIcon={false}
      renderOption={(props, option) => (
        <MenuItem {...props} key={`${option.id}-${option.idType}-${option.text}`}>
          {option ? getEquipmentLabel(option) : '-'}
        </MenuItem>
      )}
      getOptionLabel={(equipment) => (equipment ? getEquipmentLabel(equipment) : '-')}
      // per default the auto-complete component will use the getOptionLabel,
      // compare it with the given input text and will filter out all that do not match.
      // that is a problem when having sapNumbers were we remove the leading zeros
      // 00000008321003 will be rendered as 8321003 and therefor not match if the input is 0083
      // and will not be presented as an option
      // to avoid this behavior, we override the filterOptions function to force it to compare on object level
      filterOptions={(options) => options}
      isOptionEqualToValue={(a, b) => a.id === b.id && a.idType === b.idType}
    />
  )
}
